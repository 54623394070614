<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code>
          <b-col xl="4">
            <label>نقطة الضعف الأساسية </label>
            <b-form-input class="" v-model="weakName" />
          </b-col>
          <b-col xl="2">
              <b-button variant="purple" @click="updateWeak($route.params.id,weakName)"><span>تعديل</span></b-button></b-col
            >
          <br /><br />
          <b-row >
            <b-col xl="6" v-if="subweak.length > 0">
              <label>نقاط الضعف الفرعية </label>
              <b-tabs vertical content-class="col-12 col-md-8 col-lg-9">
                <!-- payment tab -->
                <b-row v-for="(sub, index) in subweak" :key="index">
                  <b-form-input class="" v-model="sub.name"  />
                  <br/>
                   <b-button  variant="purple" class="but" @click="updateWeak(sub.id,sub.name)"><span>تعديل</span></b-button>
                 <br/> <br/>
                </b-row>
                 <br/> <br/>
              </b-tabs>
            </b-col>
          </b-row>
          <br /><br />

          <b-row>
            <!-- -->
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BTab,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { codeMask } from "./code";
import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTabs,
} from "bootstrap-vue";
export default {
  name: "",

  components: {
    BDropdown,
    ToastificationContent,    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      optionWeakness: [],

      weak: "",
      subweak: [],
      codeMask,
      weakName: "",
    };
  },

  created() {
    // this.getWeak();
    this.getallWeak();
  },

  methods: {
    updateWeak(id,name){
   //console.log(id);
    let data={
        name:name,
       
      }
      //console.log(data)
      let url=`/api/v1/weaknesses/${id}`
       this.$http.put(url,data).then((res) => {
       this.$swal({
                      title: "تم التعديل بنجاح",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });     
     
    })
   },
    getallWeak() {
      this.options = [];
      this.$http.get("/api/v1/weaknesses").then((res) => {
        let weak = res.data.data.weaknesses;
        weak.forEach((ele) => {
          if (ele.id == this.$route.params.id) {
            this.weakName = ele.name;
          }
        });
        //console.log(res);
        let subWeak = res.data.data.sub_weaknesses;
        subWeak.forEach((el) => {
          if (el.parent_id == this.$route.params.id) {
            this.subweak.push(el);
          }
        });

 
      });
    },
    //
  },
};
</script>

<style scoped>
.form-control {
  font-weight: bold;
}
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 10px;
}

label {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
  font-size: 20px;
}
.align-left {
  margin-left: 750px;
}
.btn-purple {
  top: 40px !important;
}
</style>